<template>
  <div class="recognition-list">
    <div class="device-info">
      <img :src="deviceModelIcon()" alt="">
      <div class="wrap-name">
        <TooltipElement :content="deviceName" placement="right">  
          <div class="device-name">
            {{ deviceName }}
          </div>
        </TooltipElement>
      </div>
    </div>
    <div class="container-task">
      <template v-if="hasLocalFr || isLprCam || isAiCam">
        <div class="header local">
          <div class="reg-title">
            <img src="@/assets/icons/Camera.svg" alt=""> {{ $t('ai_device_recognition')/*本機辨識*/ }}
          </div>
        </div>
        <div class="local-recog">
          <TaskCardDevice v-if="hasLocalFr" mode="fr" />
          <TaskCardDevice v-if="hasLocalLpr || isAiCam" mode="lpr" />
          <TaskCardDevice v-if="hasLocalOr" mode="or" />
        </div>
      </template>
      
      <div class="header" tabindex="0" @focusout="showRecognitionOptions = false">
        <div class="reg-title">
          <img src="@/assets/icons/cloud.svg" alt=""> {{ $t('ai_could_recognition')/*雲端辨識*/ }}
        </div>
        <div class="plus" @click="toggleRecognitionOptions"><img src="@/assets/icons/plus.svg" alt=""></div>
        <transition>
          <div v-show="showRecognitionOptions" class="recog-options">
            <div v-for="option in recgOptions" :key="option.ai" class="option" @click="handleAddTask(option.ai)">
              <img :src="option.icon" alt="">
              {{ option.label }}
            </div>
          </div>
        </transition>
      </div>
      <div class="cards">
        <TaskCardAibox v-for="task in aiBoxTasks" :key="task.id" :data="task" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions, mapGetters } from 'vuex'
import { diffSecs, timeAgo } from '@/utils/lib.js'
import { getDeviceModelIcon } from '@/config/account.js'
import { lprDeviceIds } from '@/config/configLpr.js'
import { frDeviceIds } from '@/config/configFr.js'
import { aicamDeviceModelId } from '@/config/account'
import { isDevMode } from '@/utils/lib.js'

export default {
  name: 'TaskList',
  components: {
    TooltipElement: () => import('@/components/Base/TooltipElement.vue'),
    TaskCardAibox: () => import('@/components/AiBox/list/TaskCardAibox.vue'),
    TaskCardDevice: () => import('@/components/AiBox/list/TaskCardDevice.vue'),
  },
  data() {
    return {
      showRecognitionOptions: false,
      plusRecogOptions: [
        { ai: 'lpr', label: this.$t('ai_license_plate_recognition'), icon: require('@/assets/icons/LPR.svg') },
        { ai: 'or', label: this.$t('ai_object_recognition'), icon: require('@/assets/icons/OR.svg') }
      ],
      initAiboxTaskOr: {
        id: -99,  // magic number: -99
        ai: 'or',
        aiboxName: '',
        status: '',
        sourceId: '',
        aiboxId: '',
        notice: '',
        config: {
          version: '1.0',
          roi: {
            x1: 0,
            y1: 0,
            x2: 1,
            y2: 1,
          },
          area1: [],
          area2: [],
          area3: [],
          mode: 1, // 1-2, 1: or, 2: motion
          or: {
            personLevel: 3,     // 0-5, 0 為 disable, default: 3
            carLevel: 3,        // 0-5, 0 為 disable, default: 3
            bikeLevel: 3,       // 0-5, 0 為 disable, default: 3
            truckLevel: 3,      // 0-5, 0 為 disable, default: 3
            busLevel: 3,        // 0-5, 0 為 disable, default: 3
            recgFpsLevel: 3,    // 0-5, 0為ASAP , fps value: 3/5/7/10/15, default: 3
            staySec: 5,         // 0-60 (s), default: 5
            repeatInterval: 5,  // 1~60 (mins), default: 5
          },
          md: {
            motionLevel: 2,     // 0-3 0未啟動 1 2(default) 3 
            recgFpsLevel: 3,    // 0-5, 0為ASAP , fps value: 3/5/7/10/15, default: 3
            staySec: 5,         // 0-60 (s), default: 5
            repeatInterval: 5,  // 1~60 (mins), default: 5
          },
        },
        subscribers: [],
      },
      initAiboxTaskLpr: {
        id: -99,  // magic number: -99
        ai: 'lpr',
        sourceId: '',
        aiboxId: '',
        notice: '',
        notifyFilterMode: 0,
        rtspUrl: '',
        config: {
          version: '1.0',
          aiModelType: 'tw',
          lprTw: {
            eventDuration: 210,
            eventPostMode: 1,
            repostDataStoreLimit: 1000,
            acceptDiffNum: 1,
            lprSizeRatio: 5,
            isCompleteMode: 0,
            confirmCount: 1,
            detectMode: 1,
            indent: 0.01,
            lprFrequency: 30,
            roi: {
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 1,
            },
            tagFilter: []
          },
          lprJp: {
            eventDuration: 210,
            eventPostMode: 1,
            repostDataStoreLimit: 1000,
            acceptDiffNum: 2,
            lprSizeRatio: 5,
            isCompleteMode: 0,
            confirmCount: 1,
            detectMode: 1,
            indent: 0.01,
            lprFrequency: 30,
            roi: {
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 1,
            },
            tagFilter: []
          },
          lprVn: {
            eventDuration: 210,
            eventPostMode: 1,
            repostDataStoreLimit: 1000,
            acceptDiffNum: 1,
            lprSizeRatio: 5,
            isCompleteMode: 0,
            confirmCount: 1,
            detectMode: 1,
            indent: 0.01,
            lprFrequency: 30,
            roi: {
              x1: 0,
              y1: 0,
              x2: 1,
              y2: 1,
            },
            tagFilter: []
          },
        },
        subscribers: [],
      },
    }
  },
  computed: {
    ...mapState('aibox', ['isEdit', 'taskId', 'aiBoxes', 'aiBoxTasks']),
    ...mapGetters('aibox', ['deviceModelId', 'deviceName', 'aiBoxTask']),
    ...mapGetters('recgFr', ['deviceModelId']),
    ...mapGetters('recgLpr', ['isAiCam', 'isLprCam']),
    hasLocalLpr() {
      return lprDeviceIds.includes(this.deviceModelId)
    },
    hasLocalFr() {
      return frDeviceIds.includes(this.deviceModelId)
    },  
    hasLocalOr() {
      return aicamDeviceModelId.includes(this.deviceModelId)
    },
    hasLocalTask() {
      return this.hasLocalLpr || this.hasLocalFr || this.hasLocalOr
    },
    recgOptions() {
      return isDevMode() ? this.plusRecogOptions : this.plusRecogOptions.filter(item => item.ai === 'or')
    },
  },
  methods: {
    ...mapMutations('aibox', ['updateAiMode', 'updateIsEdit', 'updateTaskId', 'updateShowAiboxPortal', 'updateParamAiboxPortal']),
    ...mapActions('aibox', ['initialAiBoxTask']),
    diffSecs,
    timeAgo,
    deviceModelIcon() {
      const icon = getDeviceModelIcon(this.deviceModelId)
      return require(`@/assets/icons/device_model_${icon}.svg`)
    },
    toggleRecognitionOptions() {
      this.showRecognitionOptions = !this.showRecognitionOptions
    },
    handleAddTask(ai) {
      // 先檢查是否已經有新增的任務，若有則提示使用者
      if (this.aiBoxTasks.some(task => task.id === -99)) {
        this.updateParamAiboxPortal({ info: 'newTaskAdded', action: null })
        this.updateShowAiboxPortal(true)
        this.showRecognitionOptions = false
        return
      }

      // 檢查是否還有可用的辨識盒
      if (this.aiBoxes.filter(item => (item[`${ai}Capability`] - item[`${ai}UsedResouces`]) > 0).length === 0) {
        this.updateParamAiboxPortal({ info: 'resourceNotAvailable', action: null })
        this.updateShowAiboxPortal(true)
        this.showRecognitionOptions = false
        return
      }

      const payload = ai === 'lpr' ? this.initAiboxTaskLpr : this.initAiboxTaskOr
      this.initialAiBoxTask(payload)
      this.showRecognitionOptions = false
    },
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.recognition-list {
  position: relative;
  width: 23%;
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  background: #282942;
  border-right: 1px solid #4A5C78;
}

.device-info {
  width: 100%;
  min-height: 72px;
  max-height: 72px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background: #4A5C78;
  padding: 12px 16px; 
  @include font_style(px2rem(18), px2rem(24), #ffffff, 700);
  img {
    width: 20px;
    height: 20px;
  }

  .wrap-name {
    width: calc(100% - 32px);
    .device-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
}

.container-task {
  width: 100%;
  height: calc(100% - 72px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 12px 3px 12px 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  @include font_style(px2rem(16), px2rem(24), #FFE99F, 700);
}

.local-recog {
  padding: 6px 0px;
  margin-bottom: 4px;
}

.reg-title {
  display: flex;
  align-items: center;
  img {
    width: 16px;
    height: 16px;
    @include filter_FFE99F;
    margin-right: 8px;
  }
}

.plus {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #ffffff1a;
  }
  &:active {
    background: #ffffff80;
  }
}

.recog-options {
  position: absolute;
  width: 200px;
  left: calc(100% - 8px);
  padding: 6px 0;
  background: #151B35;
  border: 1px solid #4A5C78;
  border-radius: 9px;
  z-index: 12;
}

.option {
  padding: 6px 12px;
  display: flex;
  justify-content: center;
  color: #ffffff;
  font-weight: 400;
  cursor: pointer;
}
.option:hover {
  background: #4A5C78;
}

.cards {
  padding: 8px 0px;
}

.recognition-card {
  background: #4A5C7866;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.recog-selected {
  background: #4A5C78;
}

.title {
  width: 60%;
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  display: flex;
  justify-content: start;
}

.reg-title img, .title img, .option img {
  margin-right: 12px;
  position: relative;
  top: 2px;
}

.title .name {
  width: 60%;
}

.status {
  position: absolute;
  top: 12px;
  right: 0;
  max-width: 150px;
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
  padding: 6px 12px;
  background: #6E7D93;
  border-radius: 9px 0px 0px 9px;
}

.error {
  background: #F94144;
}

.item {
  display: flex;
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin-top: 4px;
  position: relative;
}

.item .label {
  width: 40%;
}

.item .value {
  width: 60%;
  word-break: break-all;
}

.time {
  color: #8CC24D;
}

.orange {
  color: #D8A848;
}

.red {
  color: #F94144;
}

.icons {
  display: flex;
  position: absolute;
  right: 0px;
  top: -3px;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.icon:hover {
  background: #FFFFFF33;
}

.icon img {
  width: 16px;
  height: 16px;
}

.icons .icon:first-of-type {
  margin-right: 4px;
}

</style>